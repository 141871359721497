import * as A from "../assets/images";

export const assigmentData = {
    title: 'individuals_mobd_for_individuals',
    text: 'individuals_take_a_good_care',
    imgSrc: A.individualsAssigment,
    isExternalButtonLink: true,
}

export const designedData = [
    {
        text: 'individuals_parents_and_families',
        imgSrc: A.youngDrivers
    },
    {
        text: 'individuals_auto_enthusiasts',
        imgSrc: A.enthusiast
    },
    {
        text: 'individuals_buyers_and_sellers',
        imgSrc: A.sellingCar
    }
]

export const whyMobdData = [
    {
        title: 'individuals_are_you_a_parent',
        text: 'individuals_advanced_telematics_technology',
        imgSrc: A.whyMobdIndividuals11
    },
    {
        title: 'individuals_are_you_an_auto_enthusiast',
        text: 'individuals_monitor_everything',
        imgSrc: A.whyMobdIndividuals12
    },
    {
        title: 'individuals_buying_or_selling_a_car',
        text: 'individuals_the_future_of_auto_sales',
        imgSrc: A.whyMobdIndividuals13
    }
]

export const usefulData = [
    {
        src: A.effectiveFuelConsumptionIcon,
        title: 'useful_data_effective_fuel_consumption',
    },
    {
        src: A.tiresAndSuspensionIcon,
        title: 'useful_data_tires_and_suspensions_use',
    },
    {
        src: A.maintenanceScheduleIcon,
        title: 'useful_data_maintenance_schedule',
    },
    {
        src: A.accelerationIcon,
        title: 'useful_data_acceleration',
    },
    {
        src: A.engineLoadIcon,
        title: 'useful_data_engine_load',
    },
    {
        src: A.gasEmissionsIcon,
        title: 'useful_data_gas_emissions',
    },
    {
        src: A.gForceIcon,
        title: 'useful_data_g_force',
    },
    {
        src: A.elevationIcon,
        title: 'useful_data_elevation',
    },
    {
        src: A.corneringIcon,
        title: 'useful_data_cornering',
    },
];

export const pricingData = {
    price_per_device_annually: 'specific_pricing_individuals_price_per_device_annually',
    price_per_device_annually_currency: 'specific_pricing_individuals_price_per_device_annually_currency',
    price_per_device_monthly: 'specific_pricing_individuals_price_per_device_monthly',
    price_per_device_monthly_currency: 'specific_pricing_individuals_price_per_device_monthly_currency',
    price_unit: 'specific_pricing_individuals_price_unit',
    title: 'specific_price_individual_title',
    subtitle: 'specific_price_individual_individual_license',
    topRow: {
        firstColumn: {
            mobile: {
                title: 'specific_price_general_mobile_platforms',
                items: ['specific_price_general_unlimited_users', 'specific_price_general_trips_log', 'specific_price_general_driver_score_short', 'specific_price_general_early_diagnostics', 'specific_price_general_accident_detection', 'specific_price_general_expense_management', 'specific_price_general_hud', 'specific_price_general_vehicle_timeline']
            },
        },
        secondColumn: {
            additional: {
                title: 'specific_price_general_additional_features',
                items: ['specific_price_general_firmware_updates', 'specific_price_general_premium_support']
            }
        }
    },
    hardWareFirstColumn: ['specific_price_general_connectivity_via_mobile', 'specific_price_general_one_user_per_device', 'specific_price_general_trips_log', 'specific_price_general_driver_score'],
    hardwareSecondColumn: ['specific_price_general_dtc_alert', 'specific_price_general_hud', 'specific_price_general_vehicle_timeline', 'specific_price_general_manual_firmware']
}